<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import CourseRowCard from '@/components/courses/course-row-card/CourseRowCard.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin';
  import getRandomMotivationalMessage from '@/utils/getRandomMotivationalMessage';

  export default {
    async created() {
      await this.fetchUser();
      await this.fetchAllAuthors();
      await this.fetchCourses();
    },
    mixins: [userFSDocumentMixin],
    data() {
      return {
        isLoadingCourses: false,
      };
    },
    components: {
      CurvedHeader,
      CourseRowCard,
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CoursesModule', { fetchAllCourses: 'fetchAll' }),
      ...mapActions('CategoriesModule', { fetchAllCategories: 'fetchAll' }),
      ...mapActions('AuthorsModule', { fetchAllAuthors: 'fetchAll' }),
      async fetchCourses() {
        this.isLoadingCourses = true;
        try {
          await this.fetchAllCourses();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingCourses = false;
        }
      },
      /**
       * Go to `CoursesByPurpose` route, where only courses of a specific `purpose` are shown.
       * @param {'learn' | 'meditate' | 'sleep'} purpose purpose of the courses.
       */
      goToFilteredByPurposePage(purpose) {
        this.$router.push({
          name: 'CoursesByPurpose',
          params: {
            purpose,
          },
        });
      },
    },
    computed: {
      ...mapGetters('AuthModule', ['getCurrentUserFromFirebaseAuth']),
      ...mapGetters('OrganizationsModule', {
        organization: 'getOrganization',
        organizationLogo: 'getOrganizationLogoUrl',
      }),
      ...mapGetters('CoursesModule', ['recommendedCourses', 'newOnTheAppCourses']),
      timeFieldOfTheDay() {
        const hour = new Date().getHours();

        if (hour < 12) {
          return 'Good morning';
        } else if (hour < 18) {
          return 'Good afternoon';
        } else {
          return 'Good evening';
        }
      },
      motivationalMessageContent() {
        return getRandomMotivationalMessage();
      },
    },
  };
</script>
